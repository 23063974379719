<template>
  <div>
    <div class="highlights" :class="{ 'd-none': currentChart !== 'events' }">
      <div class="pr-1">
        <chart
          format="pie"
          title="Mensagens por Eventos"
          :loading="loadingEvents"
          :data="dataMessagesEvents" />
      </div>
    </div>
    <div class="highlights" :class="{ 'd-none': currentChart !== 'providers' }">
      <div class="pr-1">
        <chart
          format="pie"
          title="Mensagens por provedor"
          :loading="loadingProviders"
          :data="dataMessagesProviders" />
      </div>
    </div>
    <div class="highlights" :class="{ 'd-none': currentChart !== 'devices' }">
      <div class="pr-1">
        <chart
          format="pie"
          title="Mensagens por dispositivos"
          :loading="loadingDevices"
          :data="dataMessagesDevices" />
      </div>
    </div>
    <div class="highlights" :class="{ 'd-none': currentChart !== 'browsers' }">
      <div class="pr-1">
        <chart
          format="pie"
          title="Mensagens por navegadores"
          :loading="loadingBrowsers"
          :data="dataMessagesBrowsers" />
      </div>
    </div>

    <div class="highlights" :class="{ 'd-none': currentChart !== 'status' && currentChart !== '' }">
      <div class="row">
        <div class="col-sm-4">
          <div class="card">
            <MsgGreen />
            <h3>{{sentMessagesTotal | humanSize}}</h3>
            <p>Mensagens enviadas</p>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card">
            <Tarifada />
            <h3>{{sentMessagesTarifeds | humanSize}}</h3>
            <p>Mensagens Tarifadas</p>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card">
            <Fail />
            <h3>{{sentMessagesFaileds | humanSize}}</h3>
            <p>Erros</p>
          </div>
        </div>
      </div>
      <div class="pr-1">
        <chart
          format="pie"
          ref="chart"
          title="Mensagens Enviadas"
          :data="dataSentMessages"
          :loading="loadingSentMessages" />
      </div>
    </div>
    <!-- <email-report :date-gte="dateGte" :date-lte="dateLte" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Fail from '@/components/rebranding/animLottie/Fail.vue';
import MsgGreen from '@/components/rebranding/animLottie/MsgGreen.vue';
import Tarifada from '@/components/rebranding/animLottie/Tarifada.vue';
// import EmailReport from '@/components/rebranding/mails/insights/ReportList.vue';

import Chart from '@/components/rebranding/Chart.vue';
import axios from '@/plugins/axios';
import moment from 'moment';

export default {
  name: 'MailReportsStats',
  components: {
    Fail,
    MsgGreen,
    Tarifada,
    Chart,
    // EmailReport,
  },
  props: ['dateGte', 'dateLte', 'accounts'],
  computed: {
    timeWindow() {
      if (moment(this.endDate).diff(moment(this.startDate), 'days')) {
        return 'daily';
      }
      return 'hourly';
    },
    events() {
      console.log('dataMessagesEvents', this.dataMessagesEvents);

      return 0;
    },
    providers() {
      console.log('dataMessagesProviders', this.dataMessagesProviders);

      return 0;
    },
    devices() {
      console.log('dataMessagesDevices', this.dataMessagesDevices);

      return 0;
    },
    browsers() {
      console.log('dataMessagesBrowsers', this.dataMessagesBrowsers);

      return 0;
    },
  },
  watch: {
    dateGte(newVal, oldVal) {
      console.log('Prop changed [dateGte]: ', newVal, ' | was: ', oldVal);
      this.startDate = newVal.replace(/\s/, 'T');
    },
    dateLte(newVal, oldVal) {
      console.log('Prop changed [dateLte]: ', newVal, ' | was: ', oldVal);
      this.endDate = newVal.replace(/\s/, 'T');
    },
  },
  data() {
    const date = new Date().toISOString().substring(0, 10);

    return {
      timeout: 0,

      sentMessagesTotal: 0,
      sentMessagesTarifeds: 0,
      sentMessagesFaileds: 0,

      loadingSentMessages: false,
      dataSentMessages: [],

      currentChart: '',

      loadingEvents: false,
      dataMessagesEvents: [],

      loadingProviders: false,
      dataMessagesProviders: [],

      loadingDevices: false,
      dataMessagesDevices: [],

      loadingBrowsers: false,
      dataMessagesBrowsers: [],

      startDate: this.dateGte.replace(/\s/, 'T') || `${date}T00:00:01`,
      endDate: this.dateLte.replace(/\s/, 'T') || `${date}T23:59:59`,
    };
  },
  mounted() {
    console.log('Prop changed [mounted]:', this.startDate, this.endDate);
    this.fetch();
  },
  filters: {
    humanSize: (value) => {
      if (!value) return 0;

      if (value < 1000) return value;

      let base = 1;
      let sulfix = '';

      if (value >= 1000000000) {
        base = 1000000000;
        sulfix = 'Bilhão(ões)';
      } else if (value >= 1000000) {
        base = 1000000;
        sulfix = 'Milhão(ões)';
      } else if (value >= 1000) {
        base = 1000;
        sulfix = 'Mil';
      }

      // return `${[value, base]} Mil`;
      return `${(value / base).toFixed()} ${sulfix}`;
    },
  },
  methods: {
    choose(chart) {
      this.currentChart = chart;
      console.log('currentChart', chart);
    },
    fetch(callback) {
      this.loadingSentMessages = true;
      this.loadingEvents = true;
      this.loadingProviders = true;
      this.loadingDevices = true;
      this.loadingBrowsers = true;

      clearTimeout(this.timeout);

      // Prevent multiple requests
      this.timeout = setTimeout(() => {
        this.fetchAll().then(() => {
          if (callback) callback();
        });
      }, 200);
    },
    async fetchAll() {
      await this.fetchSentMessages();
      await this.fetchEvents();
      console.log('localTick::fetchAll');
      return true;
    },
    async fetchSentMessages() {
      const tarifedFields = [
        {
          field: 'status',
          values: ['sent', 'blocked', 'bounced', 'dropped', 'accepted', 'delivered', 'opened'],
        },
        {
          field: 'account_id',
          values: this.accounts,
        },
      ];

      const failedFields = [
        {
          field: 'status',
          values: ['failed'],
        },
        {
          field: 'account_id',
          values: this.accounts,
        },
      ];

      const tarifeds = await this.fethMessageInsights(tarifedFields);

      const faileds = await this.fethMessageInsights(failedFields);
      /* const faileds = await this.fethMessageInsights('status', [
        'failed',
      ]); */

      this.dataSentMessages = [
        {
          values: tarifeds,
          group_by: 'Tarifadas',
        },
        {
          values: faileds,
          group_by: 'Falhas',
        },
      ];

      console.log({ tarifeds, faileds });

      this.loadingSentMessages = false;

      const tarifedsC = tarifeds.reduce((c, item) => c + item[1], 0);
      const failedsC = faileds.reduce((c, item) => c + item[1], 0);

      this.sentMessagesTotal = tarifedsC + failedsC;
      this.sentMessagesTarifeds = tarifedsC;
      this.sentMessagesFaileds = failedsC;
    },
    async fetchEvents() {
      this.dataMessagesEvents = await this.fethEventInsights('status', 'account_id', this.accounts);
      this.loadingEvents = false;

      this.dataMessagesProviders = await this.fethEventInsights('platform', 'account_id', this.accounts);
      this.loadingProviders = false;

      this.dataMessagesDevices = await this.fethEventInsights('device_type', 'account_id', this.accounts);
      this.loadingDevices = false;

      this.dataMessagesBrowsers = await this.fethEventInsights('browser', 'account_id', this.accounts);
      this.loadingBrowsers = false;
    },
    fethMessageInsights(fields) {
      let filters = [];

      if (fields) filters = fields;

      const params = {
        begin_date: this.startDate,
        direction: 'outbound',
        end_date: this.endDate,
        time_window: this.timeWindow,
        filters,
      };

      return axios().post('/email/insights', params).then((resp) => resp.data.map((item) => [
        item.date_time,
        item.values.reduce((accumulator, value) => accumulator + value, 0),
      ]));
    },
    fethEventInsights(groupBy, field, values) {
      let filters = [];

      if (field) filters = [{ field, values }];

      const params = {
        begin_date: this.startDate,
        direction: 'outbound',
        end_date: this.endDate,
        group_by: groupBy,
        time_window: this.timeWindow,
        filters,
      };

      return axios().post('/email/insights/events', params).then((resp) => {
        const reorder = {};

        resp.data.forEach((item) => {
          const date = item.date_time;

          Object.entries(item.values).forEach(([group, value]) => {
            if (!reorder[group]) reorder[group] = [];

            reorder[group].push([date, value]);
          });
        });

        return Object.keys(reorder).map((group) => ({ group_by: group, values: reorder[group] }));
      });
    },
    channel() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        group_by: 'channel',
      };

      axios().post('webchat/statistics/data', params).then((resp) => {
        console.log('webchat/statistics/data', resp.data);
        // parse data
        this.dataChannels = resp.data.map((item) => {
          item.values = item.values.map((value) => [value.date, value.count]);
          return item;
        });

        // console.log('chart::2', this.date);

        // this.series_line = Object.values(series);
        this.loadingChannels = false;
      });
    },
    finished() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        group_by: 'channel',
      };

      axios().post('webchat/statistics/finished', params).then((resp) => {
        // this.data = resp;
        // this.series_line = Object.values(series);
        console.log('webchat/statistics/finished', resp.data);
      });
    },
    exportData(format) {
      this.$refs.chart.exportData(format);
    },
  },
};
</script>

<style lang="scss" scoped>
.highlights {
  .card {
    padding: .92rem;

    h3 {
      margin: .62rem 0;
      font-size: 2rem;
      font-weight: 400;
    }

    &::v-deep {
      .svg_wrapper {
        z-index: 1 !important;
        margin: 0 !important;
        top: 0 !important;
        width: 64px !important;
        height: 64px !important;
      }
    }
  }
}
</style>
